var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "button",
    {
      on: {
        click: function ($event) {
          $event.stopPropagation()
          $event.preventDefault()
          return _vm.data.click.apply(null, arguments)
        },
      },
    },
    [
      _c(
        "svg",
        {
          attrs: {
            width: "40",
            height: "40",
            viewBox: "0 0 40 40",
            fill: "none",
            xmlns: "http://www.w3.org/2000/svg",
          },
        },
        [
          _c("circle", {
            attrs: { cx: "20", cy: "20", r: "20", fill: "#48B26B" },
          }),
          _c("path", {
            attrs: {
              d: "M17.1954 27.5C16.7896 27.5 16.3834 27.3387 16.0739 27.0158L10.4647 21.1692C9.8451 20.5234 9.8451 19.4765 10.4647 18.8307C11.0843 18.1849 12.0887 18.1849 12.7083 18.8307L17.1954 23.5078L27.2918 12.9843C27.9114 12.3387 28.916 12.3385 29.5352 12.9843C30.1549 13.6301 30.1549 14.6772 29.5352 15.3228L18.3172 27.0158C18.0074 27.3385 17.6014 27.5 17.1954 27.5Z",
              fill: "white",
            },
          }),
        ]
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }